import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  CardActionArea,
  Tooltip,
  Box,
} from '@mui/material';
import useAuth from '../utils/useAuth.js';

function HubPage() {
  const { user } = useAuth();
  const [userWorlds, setUserWorlds] = useState([]);
  const [otherWorlds, setOtherWorlds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorlds = async () => {
      try {
        const resUserWorlds = await axios.get(
          `/api/firebase/worlds/${user.uid}`
        );

        setUserWorlds(resUserWorlds.data);

        const resOtherWorlds = await axios.get(
          `/api/firebase/other-worlds/${user.uid}`
        );

        setOtherWorlds(resOtherWorlds.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (user) {
      fetchWorlds();
    }
  }, [user]);

  const handleWorldClick = (world) => {
    navigate(`/world/${world.id}`);
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" gutterBottom>
        Hub Page
      </Typography>

      <Typography variant="h5" gutterBottom>
        Your Worlds
      </Typography>
      <Grid container spacing={3}>
        {userWorlds.map((world, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardActionArea onClick={() => handleWorldClick(world)}>
                <Tooltip title={world.description}>
                  <CardContent>
                    <Typography variant="h5">{world.name}</Typography>
                  </CardContent>
                </Tooltip>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h5" gutterBottom>
        Other Worlds
      </Typography>
      <Grid container spacing={3}>
        {otherWorlds.map((world, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardActionArea onClick={() => handleWorldClick(world)}>
                <Tooltip title={world.description}>
                  <CardContent>
                    <Typography variant="h5">{world.name}</Typography>
                  </CardContent>
                </Tooltip>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => navigate('/new-world')}>
          Create a new world
        </Button>
      </Box>
    </Box>
  );
}

export default HubPage;
