import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Hub from './components/Hub.js';
import NewWorld from './components/NewWorld.js';
import CharacterCreation from './components/CharacterCreation.js';
import Story from './components/Story.js';
import Login from './components/Login.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/new-world" element={<NewWorld />} />
          <Route path="/character-creation" element={<CharacterCreation />} />
          <Route path="/story" element={<Story />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
