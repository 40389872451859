import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../utils/firebase';

function CharacterCreationPage() {
  const [keywords, setKeywords] = useState('');
  const [characterDescription, setCharacterDescription] = useState('');
  const [isCharacterGenerated, setIsCharacterGenerated] = useState(false);
  const navigate = useNavigate();

  const handleCreateCharacterClick = async () => {
    try {
      const res = await axios.post('/api/generateContent', {
        prompt: keywords,
      });

      setCharacterDescription(res.data.data);
      setIsCharacterGenerated(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const res = await axios.get('/api/firebase/user', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        setUser(res.data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);

  const handleAcceptClick = async () => {
    if (user) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const res = await axios.post(
          '/api/firebase/character',
          {
            uid: user.uid,
            description: characterDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      } catch (error) {
        console.error('Error:', error);
      }

      navigate('/story');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        maxWidth: '80%',
        margin: 'auto',
      }}
    >
      <Typography variant="h2" component="h1">
        Create a new character
      </Typography>
      <TextField
        placeholder="Enter some keywords that describe your character."
        fullWidth
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        disabled={isCharacterGenerated}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateCharacterClick}
        disabled={isCharacterGenerated}
        sx={{ width: '200px' }}
      >
        Create Character
      </Button>
      {isCharacterGenerated && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
            alignItems: 'center', // This line makes sure elements are centered
          }}
        >
          <TextField
            value={characterDescription}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptClick}
            sx={{ width: '200px' }}
          >
            Accept
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default CharacterCreationPage;
