import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';

function StoryPage() {
  const [chatInput, setChatInput] = useState('');
  const [chatLog, setChatLog] = useState([]);

  const handleSendMessage = async () => {
    // Append user message to chat log
    const newMessage = { sender: 'User', message: chatInput };
    setChatLog([...chatLog, newMessage]);

    try {
      // Send the chat input to OpenAI API
      const res = await axios.post('/api/generateContent', {
        prompt: chatInput,
      });

      // Append OpenAI's response to the chat log
      const botMessage = { sender: 'Bot', message: res.data.data };
      setChatLog((prevChatLog) => [...prevChatLog, botMessage]);
    } catch (error) {
      console.error('Error:', error);
    }

    // Clear the chat input
    setChatInput('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        maxWidth: '80%',
        margin: 'auto',
      }}
    >
      <Typography variant="h2" component="h1">
        Story
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          height: '60vh',
          overflowY: 'scroll',
          border: '1px solid',
          padding: '10px',
          fontSize: '20px',
        }}
      >
        {chatLog.map((chat, i) => (
          <Typography key={i}>
            <strong>{chat.sender}:</strong> {chat.message}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          width: '100%',
        }}
      >
        <TextField
          placeholder="Type your message here..."
          fullWidth
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default StoryPage;
