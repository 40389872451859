import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../utils/firebase';

function NewWorldPage() {
  const [keywords, setKeywords] = useState('');
  const [worldDescription, setWorldDescription] = useState('');
  const [isWorldGenerated, setIsWorldGenerated] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const res = await axios.get('/api/firebase/user', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        setUser(res.data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);

  const handleCreateWorldClick = async (keywords) => {
    try {
      const res = await axios.post('/api/generateContent', {
        prompt: keywords,
      });
      setWorldDescription(res.data.data);
      setIsWorldGenerated(true);
    } catch (error) {
      console.error('Error in handleCreateWorldClick:', error);
      if (error.response) {
        console.error('Error response:', error.response);
      }
    }
  };

  const handleAcceptClick = async () => {
    const world = {
      name: 'TestName', // Put the actual world name here
      description: worldDescription,
      owner: user.uid,
      created: Date.now(), // Current timestamp
    };

    try {
      const response = await axios.post('/api/firebase/world', world);
      // Check if the request was successful
      if (response.status === 200) {
        // After successfully adding the world, redirect to the Character Creation page
        navigate('/character-creation');
      } else {
        console.error('Error adding world:', response.data);
      }
    } catch (error) {
      console.error('Error adding world:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        maxWidth: '80%',
        margin: 'auto',
      }}
    >
      <Typography variant="h2" component="h1">
        Create a new world
      </Typography>
      <TextField
        placeholder="Enter some keywords that describe your world."
        fullWidth
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleCreateWorldClick(keywords)}
        disabled={isWorldGenerated}
        sx={{ width: '200px' }}
      >
        Create World
      </Button>

      {isWorldGenerated && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <TextField
            value={worldDescription}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptClick}
            sx={{ width: '200px' }}
          >
            Accept
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default NewWorldPage;
