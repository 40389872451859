import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../utils/firebase.js'; // <- Update the path

function Login({ user, setUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is already logged in');
        navigate('/hub');
      } else {
        console.log('No user is logged in');
      }
    });
    return () => unsubscribe(); // Unsubscribe from the listener when the component unmounts
  }, [navigate]);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    console.log('Attempting to sign in with Google...');
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle the result here (set state, make server requests, etc.)
      })
      .catch((error) => {
        console.error('Error signing in with Google:', error);
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1>Login</h1>
      {user ? (
        <button onClick={handleSignOut}>Sign Out ({user.email})</button>
      ) : (
        <button onClick={signInWithGoogle}>Sign in with Google</button>
      )}
    </div>
  );
}

export default Login;
